import { AppRoutes } from '~sf/components/setup/MainRouter/constants';

import type { ClinicId, EcgBundleId, PatientId, ProtocolId } from './tooling/nominal-types';

type Id = string | number | null | undefined;

type Paginated = { rows?: number; page?: number };

export const QueryKey = {
  ApiInfo: query('ApiInfo'),
  Entitlements: query('entitlements'),
  Clinics: (params?: Paginated) => query(AppRoutes.Clinics, params),
  Clinic: (id: ClinicId) => query(AppRoutes.Clinics, id),
  Contact: query(AppRoutes.Contact),
  Devices: (params?: { clinicId?: ClinicId } & Paginated) => query(AppRoutes.Devices, params),
  NewDevices: (params: Paginated) => query('devices-new', params),
  Device: (id: Id) => query(AppRoutes.Devices, id),
  EcgNote: (patientId: PatientId, measurementId: Id) =>
    query(AppRoutes.Patients, patientId, 'measurements', measurementId, 'ecg-notes'),
  PatientHome: query(AppRoutes.PatientHome),
  Patients: (
    params?: {
      clinicId?: ClinicId;
      providerId?: number;
      needs_review?: number;
    } & Paginated,
  ) => query(AppRoutes.Patients, params),
  Patient: (id: PatientId) => query(AppRoutes.Patients, id),
  PatientDevices: (id: PatientId) => query(AppRoutes.Patients, id, 'devices'),
  PatientMultipleMeasurementPerDose: (id: Id, dosageId: Id) =>
    query(AppRoutes.Patients, id, 'measurements', 'ecg', dosageId),
  Profile: query('profile'),
  Providers: (params?: { clinicId?: ClinicId } & Paginated) => query(AppRoutes.Providers, params),
  Provider: (id: Id) => query(AppRoutes.Providers, id),
  StaffList: (params?: { clinicId?: ClinicId } & Paginated) => query(AppRoutes.Staff, params),
  Staff: (id: Id) => query(AppRoutes.Staff, id),
  Tutorials: query('tutorials'),
  ActionLog: (id: PatientId, params?: Paginated) =>
    query(AppRoutes.Patients, id, 'action-log', params),
  AllMessages: query('messages'),
  Messages: (protocolId: ProtocolId) => query('drug_loading_protocol', protocolId, 'messages'),
  DrugLoadingProtocol: (id: PatientId) => query(AppRoutes.Patients, id, 'drugLoadingProtocol'),
  Measurements: (id: PatientId) => query(AppRoutes.Patients, id, 'measurements'),
  Measurement: (id: Id, measurementId: Id) =>
    query(AppRoutes.Patients, id, 'measurements', measurementId),
  PatientDosages: (userId: PatientId) => query(AppRoutes.Patients, userId, 'dosage'),
  PatientCrCl: (userId: Id) => query(AppRoutes.Patients, userId, 'crcl'),
  PatientDosage: (userId: Id, dosageId: Id) =>
    query(AppRoutes.Patients, userId, 'dosage', dosageId),
  PatientDosageAllLeads: (userId: Id, dosageId: Id) =>
    query(AppRoutes.Patients, userId, 'dosage', dosageId, 'allLeads'),
  PatientManualMeasurements: (userId: Id) =>
    query(AppRoutes.Patients, userId, 'manual-measurements'),
  ClinicPatients: (id: Id) => query(AppRoutes.Clinics, id, AppRoutes.Patients),
  CountryList: query('country-list'),
  // CCT
  CCTDatasets: query(AppRoutes.AnnotationJobs),
  CCTDatasetsAssigned: query(AppRoutes.AnnotationJobs, 'assigned'),
  CCTAnnotationHistory: (jobId: Id, taskId: Id) =>
    query(AppRoutes.AnnotationJobs, jobId, 'annotations', taskId, 'history'),
  CCTAnnotators: (jobId: Id) => query(AppRoutes.AnnotationJobs, jobId, 'annotators'),
  // SCAN Mode
  ProtocolTimeline: (protocolId: ProtocolId) => query('drug_loading_protocol', protocolId),
  ProtocolPlacementOptions: (protocolId: ProtocolId) =>
    query('drug_loading_protocol', protocolId, 'placement-options'),
  EcgBundleSelectedRecording: (bundleId: EcgBundleId) =>
    query('EcgBundleSelectedRecording', bundleId),
  EcgBundleSelectedRecordingData: (bundleId: EcgBundleId, dataId: Id) =>
    query('EcgBundleSelectedRecording', bundleId, 'data', dataId),
  ManualMeasurements: (userId: PatientId) =>
    query(AppRoutes.Patients, userId, 'manual-measurements-scan'),
  LegacyNotificationLog: (protocolId: Id, params?: Paginated) =>
    query('legacy-notification-log', protocolId, params),
  NotificationLog: (protocolId: ProtocolId, params?: Paginated) =>
    query('notification-log', protocolId, params),
  AllEcgsInBundle: (bundleId: EcgBundleId) => query('all-ecgs-in-bundle', bundleId),
};

export type QueryPart = string | number | object;

/**
 * Build a query key until one element is undefined/null, and then stop & return
 * what we have so far
 */
function query(...parts: (QueryPart | undefined | null)[]): QueryPart[] {
  const mutable_queryParts: QueryPart[] = [];

  for (const part of parts) {
    if (!part) {
      break;
    }
    mutable_queryParts.push(part);
  }

  return mutable_queryParts;
}
